<template>
  <div>

    <HeaderPersonal />

    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form two w-container">
        <div class="form-regidet profil w-form">
          <a id="openLabel" @click="goToCalculator" class="button-bust faq w-button">Калькулятор ежемесячного платежа</a>
          <link rel="prerender" @click="goToCalculator">
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="66681f461fa65c6cef9d4876" data-wf-element-id="be470ab5-17a8-9531-d725-c70c94a6ea13">
            <div class="tittle-form profil">
              <h1 class="heading-form">Частые вопросы</h1>
            </div>
            <div class="faq-block">
              <div fs-accordion-initial="none" fs-accordion-element="group" class="fs_accordion-2_component">
                <div class="fs_accordion-2_embed w-embed w-script"><!--  [Finsweet Attributes] Accordion  -->
                  <!-- Тут должен быть скрипт, но он перенесён -->
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item one-last">
                  <div id="accordion-2-header-1" tabindex="0" role="button" aria-controls="accordion-2-content-1" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header">
                    <div class="text-cont">Кто может получить займ в ООО МКК «Колобок»?</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper is-active-accordion"><img src="../../../../../public/images/Check.svg" loading="lazy" alt="" class="fs_accordion-2_icon"></div>
                  </div>
                  <div id="accordion-2-content-1" aria-labelledby="accordion-2-header-1" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body faq">
                      <p class="text-standart"><span class="text-span">Какие у вас требования к заемщикам?</span><br>Получение займа доступно гражданам РФ, достигшим возраста 18 лет.</p>
                      <p class="text-standart"><span class="text-span">Какие документы нужны для получения займа?</span><br>Для получения займа необходим паспорт гражданина Российской Федерации.</p>
                      <p class="text-standart"><span class="text-span">В каком городе я могу получить займ?</span><br>Оформить займ в ООО МКК «Колобок» можно из любой точки России. Единственное условие — наличие подключения к Интернету.</p>
                      <p class="text-standart"><span class="text-span">Какой у вас режим работы?<br>‍<br>‍</span>ООО МКК «Колобок» работает круглосуточно. Вы можете получить займ в любое удобное для вас время.</p>
                      <p class="text-standart"><span class="text-span">Как узнать, одобрят ли мне займ?</span><br>Для этого вам необходимо заполнить анкету на нашем сайте. Сервис работает в режиме онлайн, поэтому все необходимые данные о себе вы предоставляете не посещая офис. После заполнения система мгновенно примет решение и отправит вам SMS-уведомление.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item">
                  <div id="accordion-2-header-2" tabindex="0" role="button" aria-controls="accordion-2-content-2" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header">
                    <div class="text-cont">Оформление займа в ООО МКК «Колобок»</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper"><img src="../../../../../public/images/Check.svg" loading="lazy" alt="" class="fs_accordion-2_icon"></div>
                  </div>
                  <div id="accordion-2-content-2" aria-labelledby="accordion-2-header-2" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body faq">
                      <p class="text-standart"><span class="text-span">Как быстро рассмотрят мою заявку?</span><br>Обычно для того чтобы мы рассмотрели заявку уходит всего несколько минут. Тем не менее, в некоторых случаях нам может потребоваться больше времени для анализа указанных вами данных. В любом случае мы обязательно с вами свяжемся.</p>
                      <p class="text-standart"><span class="text-span">Могу ли я оформить займ по телефону?</span><br>На данный момент такой возможности нет. Вы можете оформить займ только на сайте ООО МКК «Колобок».</p>
                      <p class="text-standart"><span class="text-span">Мне не приходит SMS с кодом.</span><br>Если в течение 3 минут вам не приходит SMS-код, нажмите на кнопку “Получить повторно”. В случае повторного возникновения подобной проблемы рекомендуем обратиться по адресу <a href="mailto:support@kolobok-zaim.ru">support@kolobok-zaim.ru</a>
                      </p>
                      <p class="text-standart"><span class="text-span">Мне на почту не приходят письма от ООО МКК «Колобок».<br></span>Удостоверьтесь, правильно ли вы указали адрес электронной почты. Если все введено верно, попробуйте проверить папку &quot;Спам&quot; вашего почтового ящика.</p>
                      <p class="text-standart"><span class="text-span">При оформлении займа мной были указаны неправильные банковские реквизиты.</span><br>Данные владельца банковского счета должны полностью соответствовать данным, указанным в профиле на сайте ООО МКК «Колобок». В противном случае средства перечислены не будут. Если ваша заявка была одобрена с неверно заполненными реквизитами, средства будут возвращены вашим банком на счет ООО МКК «Колобок» в течение 14 дней. После этого мы закроем займ без дополнительных комиссий, и вы сможете оставить повторную заявку с новыми реквизитами.</p>
                      <p class="text-standart"><span class="text-span">Мою заявку одобрили, но потом пришел отказ. Почему?</span><br>Скорее всего, вы либо указали недостоверные контактные данные, либо нашим операторам не удалось с вами связаться.</p>
                      <p class="text-standart"><span class="text-span">Когда я смогу оформить повторный займ?<br>‍</span><br><span><strong>·</strong> Сразу, если займ погашен в срок или с просрочкой не более 20 дней;<br><strong>·</strong> Через 7 дней, в случае просрочки более 20 дней;<br><strong>·</strong> В случае отказа в выдаче займа можете повторить запрос через 30 дней.</span></p>
                      <p class="text-standart"><span class="text-span">Прошлый займ был оплачен в срок, но в повторном займе мне отказали. Почему?</span><br>При выдаче каждого нового займа система заново анализирует вашу кредитную историю и указанные данные. Скорее всего, вы перестали удовлетворять требованиям ООО МКК «Колобок».</p>
                      <p class="text-standart"><span class="text-span">Могу ли я отказаться от займа?</span><br>Вы не можете отказаться от займа, если система уже перечислила вам деньги. Однако, после получения средств вы можете досрочно погасить свой займ.</p>
                      <p class="text-standart"><span class="text-span">Моими персональными данными воспользовались мошенники<br></span>В случае, если вы стали жертвой мошенников и Вашими паспортными данными воспользовались неизвестные лица и оформили по ним договор займа в ООО МКК «Колобок», рекомендуем:<span class="text-span"><br>‍</span><br><span><strong>·</strong> Написать заявление в правоохранительные органы о факте мошенничества с использованием ваших данных.<br><strong>·</strong> Получить «талон-уведомление» о принятии от вас заявления по факту мошенничества.<br><strong>·</strong> Направить в ООО МКК «Колобок» на электронную почту </span>
                        <a href="mailto:support@kolobok-zaim.ru"><span>support@kolobok-zaim.ru</span></a><span> либо посредством почтового отправления заявление о факте мошенничества при получении займа по вашим паспортным данным и приложить копию «талона-уведомления» полиции.<br><strong>·</strong> Обратиться непосредственно в бюро кредитных историй и оспорить информацию о наличии в кредитной истории займов, полученных с использованием ваших паспортных данных, подав в бюро кредитных историй заявление о внесении изменений и (или) дополнений в эту кредитную историю.<br><strong>·</strong> После получения Вашего заявления ООО МКК «Колобок» будет проведена проверка на факт совершения мошеннических действий при оформлении договора займа. <br><strong>·</strong> В случае признания договора займа оформленным мошенническим путем, будет проведена работа по удалению информации о займе в вашей кредитной истории.<br><strong>·</strong> Также вы имеете право обратиться с иском в суд, приложив все материалы дела и собранные доказательства.</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item">
                  <div id="accordion-2-header-1" tabindex="0" role="button" aria-controls="accordion-2-content-1" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header">
                    <div class="text-cont">Получение займа</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper is-active-accordion"><img src="../../../../../public/images/Check.svg" loading="lazy" alt="" class="fs_accordion-2_icon"></div>
                  </div>
                  <div id="accordion-2-content-1" aria-labelledby="accordion-2-header-1" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body faq">
                      <p class="text-standart"><span class="text-span">Какие способы зачисления займа доступны?</span><br>Зачисление займа производится на именную банковскую карту: VISA, MasterCard или МИР.</p>
                      <p class="text-standart"><span class="text-span">Сколько по времени занимает зачисление займа?</span><br>Зачисление денежных средств на вашу карту занимает до 10 минут, в редких случаях до нескольких часов.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item">
                  <div id="accordion-2-header-1" tabindex="0" role="button" aria-controls="accordion-2-content-1" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header">
                    <div class="text-cont">Погашение и продление займа</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper is-active-accordion"><img src="../../../../../public/images/Check.svg" loading="lazy" alt="" class="fs_accordion-2_icon"></div>
                  </div>
                  <div id="accordion-2-content-1" aria-labelledby="accordion-2-header-1" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body faq">
                      <p class="text-standart"><span class="text-span">Какими способами я могу погасить займ?</span><br>Погасить займ вы можете следующими способами:<br><br><span><strong>·</strong></span> Банковская карта – по тарифам эквайринга<br><span><strong>·</strong></span> Банковский перевод - по тарифам вашего банка;<br><br>Для моментального зачисления платежа рекомендуем оплачивать через Личный кабинет с банковской карты. В случае оплаты другим способом срок зачисления может составлять от 1 до 5 рабочих дней.</p>
                      <p class="text-standart"><span class="text-span">Можно ли досрочно погасить займ?</span><br>Да, вы можете погасить займ досрочно, оплатив полную сумму, или частично. Комиссия за досрочное погашение отсутствует. Вы платите проценты только за фактическое количество дней пользования займом. Пересчет займа можно осуществить в Личном Кабинете.</p>
                      <p class="text-standart"><span class="text-span">Что делать, если я не успеваю погасить займ в назначенный срок?</span><br>Да, продлить займ вы можете через свой Личный Кабинет на сайте ООО МКК «Колобок», нажав на кнопку &quot;Продлить&quot; и следуя дальнейшим инструкциям.</p>
                      <p class="text-standart"><span class="text-span">Есть ли возможность продления займа?</span><br>Если вы не успеваете погасить займ в назначенный срок, рекомендуем вам воспользоваться услугой продления в Личном Кабинете на сайте ООО МКК «Колобок», нажав на кнопку &quot;Продлить&quot; и следуя дальнейшим инструкциям.</p>
                      <p class="text-standart"><span class="text-span">Я внёс платеж, но деньги так и не зачислились в оплату займа.</span><br>Срок зачисления платежа составляет до 5 рабочих дней, если в течение этого времени платеж не поступил, необходимо отправить на почту <a href="mailto:support@kolobok-zaim.ru">support@kolobok-zaim.ru</a> ваши ФИО и фото/скан вашего чека оплаты. После рассмотрения проблемы наши специалисты обязательно с вами свяжутся и помогут разобраться в сложившейся ситуации.</p>
                      <p class="text-standart"><span class="text-span">Не получается произвести платеж по займу в Личном Кабинете. В чем может быть причина?</span><br>В данном случае рекомендуем вам в первую очередь очистить кэш вашего браузера, воспользовавшись комбинацией клавиш <strong>CTRL + F5</strong>.После этого следует повторить попытку оплаты. Если проблема не исчезнет, то обратитесь на почту: <a href="mailto:support@kolobok-zaim.ru">support@kolobok-zaim.ru</a>
                      </p>
                      <p class="text-standart"><span class="text-span">Какие сроки зачисления платежа по займу?</span><br>Срок зачисления платежа по займу зависит от способа погашения, точный срок нужно уточнять при оплате в Личном кабинете.</p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item">
                  <div id="accordion-2-header-1" tabindex="0" role="button" aria-controls="accordion-2-content-1" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header">
                    <div class="text-cont">Лимиты и условия получения займа</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper is-active-accordion"><img src="../../../../../public/images/Check.svg" loading="lazy" alt="" class="fs_accordion-2_icon"></div>
                  </div>
                  <div id="accordion-2-content-1" aria-labelledby="accordion-2-header-1" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body faq">
                      <p class="text-standart"><span class="text-span">Под какой процент я могу оформить займ?</span><br>ООО МКК «Колобок» предоставляет одну из самых низких процентных ставок — всего 0.8% в день.</p>
                      <p class="text-standart"><span class="text-span">Какую максимальную сумму займа я могу получить?</span><br>На сегодня максимальный кредитный лимит составляет 30 000 рублей.</p>
                      <p class="text-standart"><span class="text-span">Мне непонятны условия договора, что мне делать?</span><br>Для разъяснения непонятных для вас условий договора вы можете обратиться к нам, написав электронное письмо на почтовый ящик <a href="mailto:support@kolobok-zaim.ru">support@kolobok-zaim.ru</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div fs-accordion-element="accordion" class="fs_accordion-2_item">
                  <div id="accordion-2-header-1" tabindex="0" role="button" aria-controls="accordion-2-content-1" aria-expanded="false" fs-accordion-element="trigger" class="fs_accordion-2_header">
                    <div class="text-cont">Просроченная задолженность</div>
                    <div fs-accordion-element="arrow" class="fs_accordion-2_arrow-wrapper is-active-accordion"><img src="../../../../../public/images/Check.svg" loading="lazy" alt="" class="fs_accordion-2_icon"></div>
                  </div>
                  <div id="accordion-2-content-1" aria-labelledby="accordion-2-header-1" fs-accordion-element="content" class="fs_accordion-2_content">
                    <div class="fs_accordion-2_body faq">
                      <p class="text-standart"><span class="text-span">Что делать, если я просрочил платеж по займу?</span><br>Рекомендуем вам связаться с нами как можно скорее. Вместе мы найдем решение в сложившейся ситуации.</p>
                      <p class="text-standart"><span class="text-span">Начисляются ли дополнительные проценты в случае, если я просрочил платеж?<br>‍</span><br><span><strong>·</strong></span> В случае, если у вас возникнет просроченная задолженность, ваша льготная ставка кредитования отменяется и проценты по ставке начисляются по-умолчанию;<br><span><strong>·</strong></span> Кроме того, если вы допустите просрочку по платежу более 1 дня, то, согласно договору займа, при расчете платежей за весь ваш предыдущий период пользования займом также используется ставка по-умолчанию.</p>
                      <p class="text-standart"><span class="text-span">Что будет с моей кредитной историей в случае неуплаты займа?</span><br>Информация о вашей просроченной задолженности будет передана в бюро кредитных историй. Ваша кредитная история будет испорчена, и в дальнейшем вы не сможете оформить займ или кредит не только у нас, но и в других кредитных организациях.</p>
                      <p class="text-standart"><span class="text-span">Как мне исправить ситуацию?</span><br>Основная причина выхода клиентов на просрочку - финансовые трудности, возникшие из-за халатного отношения к планированию своего бюджета. Большинство клиентов, как правило, имеет реальную возможность изыскать денежные средства для закрытия долга, однако, по ряду причин предпочитают ждать более благоприятных для этого условий. Рекомендуем не пренебрегать такими возможностями и прислушиваться к сотрудникам отдела по работе с проблемными активами! Их основная задача - помочь Вам в поиске путей решения данной проблемы. Регламент компании по взысканию просроченной задолженности позволяет на различных этапах инициировать любые процессы в рамках действующего законодательства, которые будут способствовать скорейшему возврату задолженности. Не усугубляйте свое положение. Свяжитесь с нами заранее.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderPersonal } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'FAQ',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderPersonal
  },
  created() {
  },
  computed: {
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  methods: {
    goToCalculator() {
      this.$router.push('/calculator');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  this.initAccordions();
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });

              // Подключаем аккордеоны
              const scriptUrl = 'https://cdn.jsdelivr.net/npm/@finsweet/attributes-accordion@1/accordion.js';
              const script = document.createElement('script');
              script.async = true;
              script.src = scriptUrl;
              document.head.appendChild(script);
              script.onload = () => {
                this.initAccordions();
              };
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },

    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },

    initAccordions() {
      if (typeof window.fsAttributes !== 'undefined' && window.fsAttributes.accordion) {
        window.fsAttributes.accordion.init();
      } else {
        console.log('Accordion init function not found');
      }
    }
  }
};
</script>

<style>
</style>
